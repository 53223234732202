<template>
  <div class="preference-wrap flex-1 bg-f">
    <el-page-header
      class="header"
      @back="$router.go(-1)"
      content="偏好设置"
    >
    </el-page-header>
    <div class="content">
      <el-form
        class="cus-form com-form"
        label-width="220px"
        ref="formRef"
        :model="formModel"
        :rules="formRules"
        :hide-required-asterisk="true"
      >
        <!-- 默认命名规则设置 -->
        <div class="set-item">
          <div class="title">
            默认命名规则设置
            <el-popover placement="right" width="300" trigger="hover">
              <div v-html="resData.tip"></div>
              <i
                slot="reference"
                class="el-icon-warning-outline color-theme cursor-pointer"
              ></i>
            </el-popover>
          </div>
          <div class="p-16">
            <el-form-item
              v-for="(item, index) in cusRuleSetData"
              :key="index"
              :label="item.label"
              :prop="item.prop"
            >
              <!-- <div v-if="$route.query.mark != 'all'" slot="label">
                <el-switch
                  v-model="formModel[item.prop + '_switch']"
                  :active-value="1"
                  :inactive-value="0"
                  @change="val => handleChangeSwitch(val, { prop: item.prop })"
                >
                </el-switch>
                {{ item.label }}
              </div> -->
              <div class="top dp-flex">
                <div class="mul-sel-drag pr" style="width: 70%">
                  <el-select
                    :ref="`selRef${index}`"
                    class="w-100"
                    :disabled="!formModel[item.prop + '_switch']"
                    @change="val => handleChangeAdRule(val, { index })"
                    filterable
                    allow-create
                    v-model="formModel[item.prop]"
                    multiple
                    :placeholder="`请选择${item.label}`"
                    @visible-change="val => visibleChange(val, index)"
                  >
                    <el-option
                      v-for="(opt, i) in item.options"
                      :key="i"
                      :label="opt"
                      :value="opt"
                    >
                    </el-option>
                  </el-select>
                  <cus-drag
                    :ref="`dragWrap${index}`"
                    class="pa"
                    style="left: 0; top: 0; bottom: 0; z-index: 10; right: 30px"
                    :initList="formModel[item.prop]"
                    @sort-change="arr => sorChange(item.prop, arr)"
                    @del-item="arr => delItem(item.prop, arr)"
                    @click-drag="e => clickDrag(e, index)"
                  ></cus-drag>
                </div>
                <!-- 连接符 -->
                <el-select
                  :disabled="!formModel[item.prop + '_switch']"
                  class="small-select"
                  size="small"
                  v-model="formModel[item.prop + '_con']"
                >
                  <el-option
                    v-for="(opt, i) in connectors"
                    :key="i"
                    :label="opt.label"
                    :value="opt.value"
                  >
                  </el-option>
                </el-select>
                <el-button
                  @click="handlePreviewAll($route.query.mark)"
                  style="margin-left: 15px"
                  v-if="index == 0 && $route.query.mark != 'all'"
                  type="text"
                >
                  查看原值
                </el-button>
              </div>
              <!-- 推荐词 -->
              <div class="recommend-wrap dp-flex">
                <el-button
                  v-for="(ritem, rindex) in item.recommends"
                  :key="rindex"
                  size="small"
                  :disabled="!formModel[item.prop + '_switch']"
                  @click="
                    handleSelectRecommend(item.prop, index, ritem, rindex)
                  "
                  type="text"
                  >{{ ritem }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="handlePreviewName"
                type="primary"
                plain
                size="small"
                >预览</el-button
              >
            </el-form-item>
          </div>
        </div>
        <!-- 默认转化追踪设置 -->
        <div class="set-item"
        >
          <div class="title">默认转化追踪设置</div>
          <div class="p-16">
            <el-form-item
              v-for="(item, index) in cusTraceData"
              :key="index"
              :label="item.label"
              :prop="item.prop"
            >
              <div slot="label">
                <!-- <el-switch
                  v-if="$route.query.mark != 'all'"
                  v-model="formModel[item.prop + '_switch']"
                  :active-value="1"
                  :inactive-value="0"
                  @change="val => handleChangeSwitch(val, { prop: item.prop })"
                >
                </el-switch> -->
                {{ item.label }}
                <el-popover
                  v-if="item.type == 'textarea'"
                  placement="right"
                  width="250"
                  trigger="hover"
                >
                  <div
                    v-html="
                      `<p>可帮助你了解广告成效，例如，你可以使用参数来确定广告流量的来源及哪些广告帮助提升了转化量；借助参数成效分析，你可以了解用户是点击了哪个链接前往广告的目标页，例如，你的网站或 Facebook 主页。</p>`
                    "
                  ></div>
                  <i
                    v-if="item.type == 'textarea'"
                    slot="reference"
                    class="el-icon-warning-outline color-theme cursor-pointer"
                  ></i>
                </el-popover>
              </div>
              <el-select
                v-if="item.type == 'select'"
                :disabled="!formModel[item.prop + '_switch']"
                clearable
                filterable
                v-model="formModel[item.prop]"
                :placeholder="`请选择${item.label}`"
              >
                <el-option
                  v-for="(opt, i) in item.options"
                  :key="i"
                  :label="opt.name"
                  :value="opt.id"
                >
                </el-option>
              </el-select>
              <el-button
                @click="handlePreviewAll($route.query.mark)"
                style="margin-left: 15px"
                v-if="index == 0 && $route.query.mark != 'all'"
                type="text"
              >
                查看原值
              </el-button>
              <template v-if="item.type == 'textarea'">
                <el-input
                  :disabled="!formModel[item.prop + '_switch']"
                  type="textarea"
                  v-model="formModel[item.prop]"
                  :placeholder="`请输入${item.label}`"
                  readonly 
                >
                </el-input>
                <el-button
                  :disabled="!formModel[item.prop + '_switch']"
                  @click="handleCusParamSet(formModel[item.prop])"
                  type="text"
                >
                  参数设置
                </el-button>
              </template>
            </el-form-item>
          </div>
        </div>
        <!-- 默认广告结构设置 -->
        <div
          v-if="$route.query.mark == 'all' || $route.query.mark == 'ad'"
          class="set-item"
        >
          <div class="title">默认广告结构设置</div>
          <div class="p-16">
            <el-form-item label="广告结构设置" prop="split_type">
              <el-radio-group v-model="formModel.split_type">
                <el-radio
                  :disabled="item.id == 2"
                  v-for="(item, index) in cusAdSetData"
                  :key="index"
                  :label="item.id"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
              <el-button
                @click="handlePreviewAll($route.query.mark)"
                style="margin-left: 15px"
                v-if="$route.query.mark != 'all'"
                type="text"
              >
                查看原值
              </el-button>
            </el-form-item>
          </div>
        </div>
        <el-form-item style="padding: 16px 16px 16px 32px">
          <el-button size="small" @click="$router.go(-1)"
            >取消</el-button
          >
          <el-button @click="handleSave" size="small" type="primary"
            >保存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 命名预览 -->
    <preview-name-dialog
      @close-dialog="
        previewNameVisible = false;
        previewData = [];
      "
      :visible="previewNameVisible"
      :tip="resData.tip"
      :data="previewData"
    ></preview-name-dialog>
    <!-- 网址追踪 -->
    <url-trace-dialog
      v-if="selDatas"
      @close-dialog="urlTraceVisible = false"
      :visible="urlTraceVisible"
      :connectors="connectors"
      :initData="initUrlTraceData"
      @create-url-trace="handleCreateUrlTrace"
      :selDatas="selDatas"
    ></url-trace-dialog>
    <!-- 自定义参数 -->
    <cus-param-dialog
      ref="cusParamRef"
      @close-dialog="cusParamVisible = false"
      :visible="cusParamVisible"
      :selDatas="selDatas"
      :tplStr="resData"
      :initData="cusParamInitData"
      @change-event="handleChangeEvent"
      @confim-cus-param="handleConfirm"
    ></cus-param-dialog>
    <!-- 查看原值-全部 -->
    <preview-all-drawer
      :visible="previewAllVisible"
      @close-drawer="previewAllVisible = false"
      :previewAllData="previewAllData"
      :tableData="batchInitDatas"
      @del-account="handleDelAccount"
    ></preview-all-drawer>
  </div>
</template>

<script>
import otherAccount from "@/views/adAssets/components/otherAccount";
import previewNameDialog from "@/views/adAssets/components/previewNameDialog";
import urlTraceDialog from "@/views/adAssets/components/urlTraceDialog";
import cusParamDialog from "@/views/adAssets/components/cusParamDialog";
import previewAllDrawer from "@/views/adAssets/components/previewAllDrawer";
import cusDrag from "../components/cusDrag";
import {
  getSetsByUser,
  getDicts,
  getPixelsByAccounts,
  setContent,
  updateUserNamingRule
} from "@/api/adAssets";
export default {
  components: {
    otherAccount,
    previewNameDialog,
    urlTraceDialog,
    cusParamDialog,
    previewAllDrawer,
    cusDrag
  },
  data() {
    return {
      resData: {
        tip: `<p>例如广告名称命名规则如下：</p><br /><p style="color: rgb(38, 38, 38);font-weight: bold;">营销目标缩写-国家代码-性别缩写-创意类型缩写</p><br /><p>我们会根据你投放时设置的营销目标，国家，性别，创意类型等进行自动命名，假如投放了多个国家或者创意类型等，我们会用|来进行分隔</p><br /><p>TRA-US-F-IMG</p><br /><p>TRA-CN|US-M-VIDEO|IMG</p>`,
        account_no: "",
        account_id: "",
        account_name: "",
        campaign_name: "",
        adset_name: "",
        ad_name: "",
        fb_pixel: "",
        gg_analytics: "",
        url_tags: "",
        split_type: 0
      },
      formModel: {
        campaign_name: [],
        adset_name: [],
        ad_name: [],
        campaign_name_switch: 1,
        adset_name_switch: 1,
        ad_name_switch: 1,
        campaign_name_con: "_",
        adset_name_con: "_",
        ad_name_con: "_",
        fb_pixel: "",
        gg_analytics: "",
        fb_pixel_switch: 1,
        gg_analytics_switch: 1,
        split_type: 0,
        url_tags: ""
      },
      formRules: {
        campaign_name: [
          {
            required: true,
            message: "广告系列命名规则不能为空",
            trigger: "change"
          }
        ],
        adset_name: [
          {
            required: true,
            message: "广告组命名规则不能为空",
            trigger: "change"
          }
        ],
        ad_name: [
          {
            required: true,
            message: "广告命名规则不能为空",
            trigger: "change"
          }
        ]
      },
      // 默认命名规则设置
      cusRuleSetData: [
        {
          label: "广告系列命名规则",
          prop: "campaign_name",
          options: [],
          recommends: [],
          recommendsCopy: []
        },
        {
          label: "广告组命名规则",
          prop: "adset_name",
          options: [],
          recommends: [],
          recommendsCopy: []
        },
        {
          label: "广告命名规则",
          prop: "ad_name",
          options: [],
          recommends: [],
          recommendsCopy: []
        }
      ],
      ruleSelData: {},
      useOtherSet: ["引用广告系列设置", "引用广告组设置"],
      // 默认转化追踪设置
      cusTraceData: [
        {
          label: "Facebook Pixel像素代码",
          prop: "fb_pixel",
          options: [],
          type: "select"
        },
        { label: "网址追踪", prop: "gg_analytics", type: "textarea" }
      ],
      urlTraceVisible: false,
      initUrlTraceData: {},
      // Google Analytics参数设置中下拉数据
      selDatas: [],
      // 自定义追踪参数
      cusParamInitData: {},
      cusParamVisible: false,
      urlProps: [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content"
      ],
      // 默认广告结构设置
      cusAdSetData: [
        { id: 0, label: "为每个受众创建一个广告组" },
        { id: 1, label: "为每个广告创建一个广告组" },
        { id: 2, label: "为每个受众创建一个广告系列" }
      ],
      // 连接符
      connectors: [
        { label: "_", value: "_" },
        { label: "-", value: "-" },
        { label: "空格", value: " " },
        { label: "/", value: "/" },
        { label: "#", value: "#" },
        { label: "*", value: "*" }
      ],
      // 命名预览
      previewNameVisible: false,
      previewData: [],
      // 查看原值-全部
      previewAllVisible: false,
      previewAllData: [],
      // 批量
      batchInitDatas: [],
      defaultSets:{}//接口获取的默认设置
    };
  },
  computed: {
    ruleProps() {
      return this.cusRuleSetData.map(item => item.prop);
    },
    resProp() {
      return this.ruleProps.map(item => item.slice(0, -5));
    },
    // 引用其它帐户设置预览弹窗数据
    deriveRuleData() {
      let arr = [];
      this.cusRuleSetData.forEach(item => {
        let obj = {
          label: item.label,
          prop: item.prop
        };
        arr.push(obj);
      });
      return arr;
    },
    deriveUrlData() {
      let arr = [];
      this.cusTraceData.forEach(item => {
        let obj = {
          label: item.label,
          prop: item.prop
        };
        arr.push(obj);
      });
      return arr;
    }
  },
  methods: {
    visibleChange(val, index) {
      if (!val) {
        this.$refs[`dragWrap${index}`][0].$el.style.display = "flex";
        this.$refs[`selRef${index}`][0].$el.firstElementChild.style.visibility =
          "hidden";
      }
    },
    clickDrag(e, index) {
      this.$refs[`selRef${index}`][0].$el.firstElementChild.style.visibility =
        "visible";
      this.$refs[`selRef${index}`][0].focus();
    },
    // 多选框的排序-排序改变
    sorChange(prop, arr) {
      // console.log("sorChange(prop,arr)", prop, arr);
      this.formModel[prop] = [...arr];
    },
    // 多选框的排序-删除tag
    delItem(prop, arr) {
      // console.log("delItem(prop, arr)", prop, arr);
      this.formModel[prop] = [...arr];
    },
    confirmQuoteOtherAccount(data) {
      this.initFn(data);
    },
    // 删除
    handleDelAccount(accounts) {
      // console.log("after-del=>get-accounts", accounts);
      this.getSetsByAccounts(accounts);
      this.getPixelsByAccounts(accounts);
    },
    // 广告账户偏好设置-保存
    setContent() {
      console.log(this.formModel);
      console.log('aaaaaaaaaaa');
      this.$showLoading();
      let accounts = [];
      if (this.$route.query.mark == "all") {
        accounts = [this.$route.query.id];
      } else {
        accounts = this.$route.query.accounts;
      }
      let props = [
          "ad_name",
          "adset_name",
          "campaign_name",
          "fb_pixel",
          "gg_analytics",
          "split_type",
          "url_tags"
        ],
        obj = {},
        name = this.formModel.fb_pixel
          ? this.cusTraceData[0].options.length
            ? this.cusTraceData[0].options.find(
                item => item.id == this.formModel.fb_pixel
              ).name
            : ""
          : "";
      props.forEach((p, i) => {
        if (i < 3) {
          if (this.formModel[p]) {
            let narr = this.formModel[p].map(f => {
              if (
                this.cusRuleSetData
                  .find(c => c.prop == p)
                  .options.every(o => o != f)
              ) {
                return f;
              } else {
                return `{${f}}`;
              }
            });
            obj[p] = narr.join(this.formModel[p + "_con"]);
          } else {
            obj[p] = "";
          }
        } else if (p == "fb_pixel") {
          obj[p] = { id: this.formModel[p], name };
        } else if (p == "gg_analytics") {
          obj[p] = this.resData[p];
        } else {
          obj[p] = this.formModel[p];
        }
      });
      let params = {
        accounts,
        ...obj
      };
      console.log('setContent-params11111',params)
      updateUserNamingRule(params).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message.success(res.comment);
          if (this.$route.query.mark == "all") {
            this.$router.push({ name: "AdAssets" });
          } else {
            this.getSetsByAccounts();
          }
        }
      });
    },
    // 自定义参数-确定
    handleConfirm(data) {
      console.log(data);
      console.log('ddddddddddddddddd');
      this.formModel.url_tags = data.cusStr;
      this.resData.url_tags = data.cusStr;
      this.formModel.gg_analytics = this.createUrlFn(
        this.resData.gg_analytics,
        data.cusStr
      );
      this.cusParamVisible = false;
    },
    // 根据广告账户获取像素
    getPixelsByAccounts(cusAccounts) {
      this.$showLoading();
      let accounts = [];
      if (this.$route.query.mark == "all") {
        accounts = [this.$route.query.id];
      } else {
        accounts = cusAccounts ? cusAccounts : this.$route.query.accounts;
      }
      getPixelsByAccounts({ accounts }).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          if (res.data.length) {
            this.cusTraceData[0].options = res.data;
          }
        }
      });
    },
    removeBracketFn(str) {
      let lBracket = str.indexOf("{"),
        _str = "";
      if (lBracket != -1) {
        _str = str.slice(lBracket + 1);
      } else {
        _str = str;
      }
      let rBracket = _str.indexOf("}");
      if (rBracket != -1) {
        _str = _str.slice(0, rBracket);
      }
      return _str;
    },
    // resData-formModel-init
    initFn(resInitData) {
      let keys = Object.keys(resInitData);
      // resData-init
      keys.forEach(k => {
        if (k == "fb_pixel") {
          this.resData[k] = resInitData[k].id;
        } else {
          this.resData[k] = resInitData[k];
        }
      });
      let str = this.createUrlFn(
        resInitData.gg_analytics,
        resInitData.url_tags
      );
      // forModel-init
      this.ruleProps.forEach(p => {
        if (resInitData[p]) {
          let con = this.connectors.find(c => {
            return resInitData[p].split(c.value).length > 1;
          });
          let arr = [];
          if (con) {
            let bracketArr = resInitData[p].split(con.value),
              narr = [];
            bracketArr.forEach(b => {
              narr.push(this.removeBracketFn(b));
            });
            arr = narr;
          } else {
            arr = [this.removeBracketFn(resInitData[p])];
          }
          this.formModel[p] = arr;
          this.formModel[`${p}_con`] = con ? con.value : "_";
        } else {
          this.formModel[p] = [];
        }
      });
      let otherKeys = ["fb_pixel", "gg_analytics", "split_type", "url_tags"];
      otherKeys.forEach(k => {
        if (k == "fb_pixel") {
          this.formModel[k] = resInitData[k].id;
        } else if (k == "split_type" && resInitData[k] == null) {
          this.formModel[k] = 0; //防止接口返回的广告结构默认为null时，split_type为null
        } 
        else {
          this.formModel[k] = resInitData[k];
        }
      });
      this.formModel.gg_analytics = str;
      // 初始数据是否是推荐词
      this.cusRuleSetData.forEach(item => {
        let arr = this.formModel[item.prop].filter(p => {
          return item.recommendsCopy.some(r => r == p);
        });
        if (arr.length) {
          arr.forEach(a => {
            let i = item.recommends.findIndex(j => j == a);
            if (i != -1) {
              item.recommends.splice(i, 1);
            }
          });
        }
      });
      // console.log("this.formModel-ing", this.formModel);
    },
    // 推荐词
    setRecommends() {
      let arr = [
          "创建日期（年月日）",
          "营销目标缩写",
          "国家中文",
          "国家代码",
          "性别缩写",
          "创意类型缩写",
          "受众类型缩写"
        ],
        other = this.useOtherSet;
      this.cusRuleSetData.forEach((item, index) => {
        if (index > 0) {
          arr.unshift(other[index - 1]);
        }
        item.recommends = JSON.parse(JSON.stringify(arr));
        item.recommendsCopy = JSON.parse(JSON.stringify(arr));
      });
    },
    // 获取命名规则下拉数据字典项
    getDicts() {
      this.$showLoading();
      getDicts().then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          if (res.data) {
            this.ruleSelData = JSON.parse(JSON.stringify(res.data));
            // res.data.ad/campaign/adset/utm;
            let props = this.resProp;
            props.forEach(p => {
              this.cusRuleSetData.find(
                item => item.prop == p + "_name"
              ).options = res.data[p].map(r => r.label);
            });
            // Google Analytics参数设置中下拉数据
            this.selDatas = res.data.utm.map(r => r.label);
          }
        }
      });
    },
    // 根据广告账号获取偏好设置数据-回显
    getSetsByAccounts(cusAccounts) {
      this.$showLoading();
      // console.log("params", params);
      getSetsByUser().then(res => {
        this.$hideLoading();
        if (res.code == 0) {
            let resInitData = JSON.parse(JSON.stringify(res.data));
            // console.log("resInitData",resInitData)
            this.defaultSets = resInitData
            this.initFn(resInitData);
        }
      });
    },
    // 下拉框改变
    handleChangeAdRule(val, { index }) {
      let arr = val.filter(item => {
        return this.cusRuleSetData[index].recommendsCopy.some(r => r == item);
      });
      if (arr.length) {
        // 输入框中有推荐词
        arr.forEach(item => {
          let _index = this.cusRuleSetData[index].recommends.findIndex(
            r => r == item
          );
          if (_index != -1) {
            this.cusRuleSetData[index].recommends.splice(_index, 1);
          } else {
            this.cusRuleSetData[index].recommends = this.cusRuleSetData[
              index
            ].recommendsCopy.filter(r => r != item);
          }
        });
      } else {
        // 输入框中无推荐词
        this.cusRuleSetData[index].recommends = JSON.parse(
          JSON.stringify(this.cusRuleSetData[index].recommendsCopy)
        );
      }
    },
    // 选中推荐词
    handleSelectRecommend(prop, index, ritem, rindex) {
      // 广告组-引用广告系列的规则；广告-引用广告组规则
      let isQuote = this.useOtherSet.some(item => item == ritem);
      if (isQuote) {
        if (ritem == this.useOtherSet[0]) {
          this.formModel[prop] = JSON.parse(
            JSON.stringify(this.formModel.campaign_name)
          );
        } else {
          this.formModel[prop] = JSON.parse(
            JSON.stringify(this.formModel.adset_name)
          );
        }
        this.cusRuleSetData[index].recommends.splice(rindex, 1);
        this.handleChangeAdRule(this.formModel[prop], { index });
      } else {
        let _index = this.formModel[prop].findIndex(item => item == ritem);
        if (_index == -1) {
          this.formModel[prop].push(ritem);
          this.cusRuleSetData[index].recommends.splice(rindex, 1);
        }
      }
    },
    // 批量操作-switch开关
    handleChangeSwitch(val, { prop }) {
      this.$set(this.formModel, `${prop}_switch`, val);
    },
    // 命名预览
    handlePreviewName() {
      let props = this.ruleProps;
      let names = ["广告系列名称为：", "广告组名称为：", "广告名称为："];
      props.forEach((item, index) => {
        let valArr = this.formModel[item].map(m => {
          if (this.ruleSelData[this.resProp[index]].find(i => i.label == m)) {
            return this.ruleSelData[this.resProp[index]].find(i => i.label == m)
              .value;
          } else {
            return m;
          }
        });
        let obj = {
          title: names[index],
          val: valArr.join(this.formModel[item + "_con"])
        };
        this.previewData.push(obj);
      });
      this.previewNameVisible = true;
    },
    // 网址追踪
    handleUrlTrace() {
      // 初始回显数据
      let obj = {};
      // console.log("this.resData.gg_analytics",this.resData.gg_analytics)
      if (this.resData.gg_analytics) {
        let arr = this.resData.gg_analytics.split("&");
        arr.forEach(item => {
          let con = this.connectors.find(c => {
            return item.split("=")[1].split(c.value).length > 1;
          });
          if (con) {
            let bracketArr = item.split("=")[1].split(con.value),
              narr = [];
            bracketArr.forEach(b => {
              narr.push(this.removeBracketFn(b));
            });
            obj[item.split("=")[0]] = narr;
            obj[item.split("=")[0] + "_con"] = con.value;
          } else {
            obj[item.split("=")[0]] = [
              this.removeBracketFn(item.split("=")[1])
            ];
            obj[item.split("=")[0] + "_con"] = "_";
          }
        });
      }
      this.initUrlTraceData = obj;
      this.urlTraceVisible = true;
    },
    // createUrl
    createUrlFn(tpl, cus) {
      let _str = "";
      if (cus) {
        if (tpl) {
          _str = tpl + "&" + cus;
        } else {
          _str = cus;
        }
      } else {
        if (tpl) {
          _str = tpl;
        } else {
          _str = "";
        }
      }
      return _str;
    },
    // 创建网址追踪
    handleCreateUrlTrace(str) {
      this.resData.gg_analytics = str;
      this.formModel.gg_analytics = this.createUrlFn(
        str,
        this.formModel.url_tags
      );
      this.urlTraceVisible = false;
    },
    // 保存
    handleSave() {
      // 调用接口保存
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.setContent();
        } else {
          return false;
        }
      });
    },
    // 查看原值-全部
    handlePreviewAll(mark) {
      let arr = [];
      switch (mark) {
        case "name":
          arr = this.cusRuleSetData;
          break;
        case "trace":
          arr = this.cusTraceData;
          break;
        case "ad":
          arr = this.cusAdSetData;
          break;
        default:
          break;
      }
      this.previewAllData = arr;
      this.previewAllVisible = true;
    },
    // 自定义追踪参数
    handleCusParamSet(val) {
      let cusData = [{ ipt: "var", sel: "param" }],
        cusStr = this.formModel.url_tags,
        arr = [];
      if (cusStr) {
        let items = cusStr.split("&");
        items.forEach(item => {
          arr.push({ ipt: "var", sel: "param" });
        });
        cusData = [...arr];
      }
      this.cusParamInitData = {
        tplStr: this.resData.gg_analytics,
        cusStr:this.resData.url_tags,
        cusData:[]
      };
      this.cusParamVisible = true;
    },
    // 找到非自定义url变量名组合原始数据
    UncusVarsStr(initData, urlProps) {
      let arr1 = initData.split("&"),
        vars = [],
        str = "";
      arr1.forEach(item => {
        let arr2 = item.split("=");
        vars.push(arr2[0]);
      });
      let firstUncusVar = vars.find(v => {
        return urlProps.every(p => p != v);
      });
      if (firstUncusVar) {
        // 去掉最后的&
        str = initData.split(firstUncusVar)[0].slice(0, -1);
      } else {
        str = initData.split(firstUncusVar)[0];
      }
      // console.log("str", str);
      return str;
    },
    // 自定义追踪参数-输入框-选择框-改变
    handleChangeEvent(str) {
      this.formModel.url_tags = str;
      this.cusParamInitData.cusStr = str;
    }
  },
  created() {
    // 推荐词
    this.setRecommends();
    // 获取命名规则下拉数据字典项
    this.getDicts();
    // 根据广告账户获取像素下拉数据
    // this.getPixelsByAccounts();
  },
  mounted() {
    // 根据广告账号获取偏好设置数据
    this.getSetsByAccounts();
  },
  watch: {}
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.preference-wrap {
  color: $theme-color;
  & > .header {
    border-bottom: 1px solid rgb(229, 230, 233);
    padding: 16px 24px 8px 8px;
  }
  .content {
    padding: 16px;
    .accounts {
      padding: 10px 0 10px 16px;
      font-size: 18px;
      @extend .color-26;
      span:first-child {
        margin-right: 24px;
      }
    }
    // 批量
    .batch-accounts {
      ul {
        height: 66px;
      }
      li {
        margin-right: 24px;
        padding: 8px 10px;
        width: 320px;
        height: 100%;
        border: 1px solid #e5e6e9;
      }
    }
    .ad-set {
      height: 32px;
      padding: 4px 16px;
    }
  }
  .set-item {
    padding: 16px;
    .title {
      padding: 16px 0 8px;
      @extend .color-26;
      i {
        margin-left: 5px;
      }
    }
  }
  .cus-form {
    .el-select__input {
      margin-left: 20px;
    }
    .small-select {
      width: auto;
      .el-input {
        width: 32px;
      }
      input {
        background-color: rgb(243, 245, 246);
        padding: 5px;
        @extend .text-a-c;
      }
      .el-input__suffix {
        @extend .dp-none;
      }
      .el-select-dropdown__item {
        @extend .f-12;
      }
    }
    .recommend-wrap {
      // li {
      //   line-height: initial;
      //   padding: 2px;
      //   color: rgb(157, 160, 163);
      //   font-size: 12px;
      //   margin-right: 5px;
      //   margin-top: 5px;
      //   cursor: pointer;
      //   &:hover {
      //     opacity: 0.7;
      //     color: $theme;
      //   }
      // }
      .el-button--text {
        color: rgb(157, 160, 163);
        &:hover {
          opacity: 0.7;
          color: $theme;
        }
      }
    }
  }
  .p-16 {
    padding: 16px;
  }
  .mul-sel-drag {
    padding: 1px 0;
    .el-select:first-child {
      .el-select__tags {
        visibility: hidden;
      }
    }
  }
}
</style>